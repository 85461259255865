<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Delivery - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
                
                <b-col md="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select disabled placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Nro Delivery:">
                    <b-form-input type="text" disabled class="text-center" v-model="delivery.code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled type="date" class="text-center" v-model="delivery.date" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button disabled @click="ModalLettersShow" type="button" class="form-control" variant="primary">Carta</b-button>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select disabled v-model="delivery.coin" :options="coins" ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Dirección:">
                    <b-form-input disabled v-model="delivery.address" ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Hora Inicio/Final:">
                    <b-form-input disabled :value="delivery.start_hour +' | '+ delivery.end_hour " ></b-form-input>
                  </b-form-group>
                </b-col>

    
                <b-col md="12">
                   <div class="table-responsive mt-3">
                    <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                      <thead class="">
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="8%" class="text-center">Codigo</th>
                          <th width="40%" class="text-center">Nombre</th>
                          <th width="10%" class="text-center">UM</th>
                          <th width="8%" class="text-center">Cantidad</th>
                          <th width="10%" class="text-center">P. Unit</th>
                          <th width="5%" class="text-center">P. Total</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in delivery_detail" :key="it">
                        <tr>
                            <td class="align-middle text-center">{{ it + 1 }}</td>
                            <td class="align-middle text-left">{{ item.code }}</td>
                            <td class="align-middle text-left">{{ item.code }}</td>
                            <td class="align-middle text-center">{{ item.name_unit_measure }}</td>
                            <td class="align-middle text-right">{{ item.quantity }}</td>
                            <td class="align-middle text-right">{{ item.unit_price }}</td>
                            <td class="align-middle text-right">{{ item.total_price }}</td>
                        </tr>
                        <tr v-if="item.observation.length > 0">
                          <td colspan="8">
                              <span v-for="(item1, it1) in item.observation" :key="it1">{{ item1 + ', '}}</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </b-col>

                
                <b-col md="12">
                  <br>
                </b-col>

                <b-col md="4">
                  <b-form-group>
                    <b-form-textarea disabled placeholder="Observaciones" rows="4" v-model="delivery.reason" ></b-form-textarea>
                  </b-form-group>
                </b-col>

                 <b-col  md="5">
                  <div class="table-responsive">
                    <table  class="table table-hover table-bordered">
                      <thead>
                        <tr>
                            <td width="35%" class="align-middle text-center">Caja</td>
                            <td width="40%" class="align-middle text-center">M. Pago</td>
                            <td width="20%" class="align-middle text-center">Monto</td>
                        </tr>
                      </thead>
                      <thead>
                        <tr v-for="(item, it) in payments" :key="it" >
                            <td class="align-middle">
                                <b-form-input disabled v-model="item.box"></b-form-input>
                            </td>
                            <td class="align-middle">
                                <b-form-select disabled v-model="item.payment_method" :options="payment_method"></b-form-select>
                            </td>
                            <td class="align-middle">
                              <b-form-input class="text-right" disabled size="sm" type="number" step="any" v-model="item.total"></b-form-input>
                            </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
         
                  
                </b-col>
                
                <b-col md="3">
                   <div class="table-responsive">
                    <table  class="table   table-hover table-lg mt-lg mb-0">
                      <tbody>
                        <tr>
                            <td width="60%" class="align-middle text-right text-total">SUBTOTAL:</td>
                            <td width="40%" class="align-middle text-right text-total">
                              <b-form-input class="text-right" size="sm" disabled type="number" v-model="delivery.subtotal" step="any"></b-form-input>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="align-middle text-right text-total">DELIVERY:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" disabled size="sm" type="number" v-model="delivery.delivery" step="any"></b-form-input>
                           </td>
                        </tr> -->
                        <tr>
                            <td class="align-middle text-right text-total">DESCUENTO:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" disabled size="sm" type="number" v-model="delivery.discount" step="any"></b-form-input>
                           </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">TOTAL:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" size="sm" disabled type="number" v-model="delivery.total" step="any"></b-form-input>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>



                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/delivery/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LetterObservation />
    <ModalLetters />
    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalLetters from './components/ModalLetter'
import DeliveryDetail from './components/DeliveryDetail'
import LetterObservation from './components/LetterObservation'

import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_delivery"],
  components:{
      LetterObservation,
      ModalLetters,
      DeliveryDetail,
      vSelect,
      ModalClients,
       Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Delivery',
      role: 3,
      delivery: {
          id_delivery:'',
          id_client:'',
          id_user:'',
          id_warehouse:1,
          code:'',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          address:'',
          observation:'',
          coin:'PEN',
          start_hour:'',
          end_hour:'',
          subtotal: (0).toFixed(2),
          delivery: (0).toFixed(2),
          discount: (0).toFixed(2),
          total: (0).toFixed(2),
          number_to_letters:'',
          state:1,
      },
      delivery_detail:[],
      clients: [],
      client:null,

      payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"004", text :'ORDEN DE PAGO'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"007", text :'CHEQUES CON LA CLÁUSULA DE "NO NEGOCIABLE"'},
          {value :"008", text :'EFECTIVO'},
          {value :"101", text :'TRANSFERENCIAS - COMERCIO EXTERIOR'},
          {value :"102", text :'CHEQUES BANCARIOS - COMERCIO EXTERIOR'},
          {value :"999", text :'CRÉDITO'},
      ],

      payments:[],
      box:'',
      id_box:'',

      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],

      //errors
      errors: {
        id_client: false,
        id_warehouse: false,
        code: false,
        coin: false,
        date: false,
        coin: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
  
    this.ViewDelivery();
  },
  methods: {
    GetCorrelative,
    SearchClients,
    modalClients,
    ModalLettersShow,
    ViewDelivery,

    GetBoxByUser,
    AddPayment,
    DeletePayment,
    TotalPayment,
    UpdateTotalPayment,


    NumberPadStart,
    UpperCase,
    ChangeTotal,

    EditDelivery,
    Validate,

    ...mapActions('Delivery',['mLoadTotalDelivery','mLoadResetDeliveryDetail','mLoadAddDeliveryDetail']),
  },

  computed: {

    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};




function ViewDelivery() {
  let id_delivery = je.decrypt(this.id_delivery);
  let me = this;
  let url = this.url_base + "delivery/view/"+id_delivery;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
          me.delivery.id_delivery = response.data.result.delivery.id_delivery;
          me.delivery.id_client = response.data.result.delivery.id_client;
          me.delivery.id_user = response.data.result.delivery.id_user;
          me.delivery.id_warehouse = response.data.result.delivery.id_warehouse;
          me.delivery.code = response.data.result.delivery.code;
          me.delivery.date = response.data.result.delivery.date;
          me.delivery.address = response.data.result.delivery.address;
          me.delivery.observation = response.data.result.delivery.observation;
          me.delivery.coin = response.data.result.delivery.coin;

          me.delivery.start_hour = response.data.result.delivery.start_hour;
          me.delivery.end_hour = response.data.result.delivery.end_hour;
    
          me.delivery.subtotal = response.data.result.delivery.subtotal;
          me.delivery.delivery = response.data.result.delivery.delivery;
          me.delivery.discount = response.data.result.delivery.discount;
          me.delivery.total = response.data.result.delivery.total;
          me.delivery.number_to_letters = response.data.result.delivery.number_to_letters;
          me.delivery.state = response.data.result.delivery.state;
          me.client = {id:response.data.result.delivery.id_client, full_name:response.data.result.delivery.client_name + " - "+response.data.result.delivery.client_document_number};

          me.delivery_detail = response.data.result.delivery_detail;
          let payments = response.data.result.payments;
          me.payments = [];
          for (let index = 0; index < payments.length; index++) {
            const element = payments[index];
            me.id_box = element.id_box;
            me.box = element.box_code+ " | "+element.user_name;
            me.payments.push(
              {id_box:element.id_box,box:element.box_code+ " | "+element.user_name, payment_method:element.payment_method, total: parseFloat(element.total).toFixed(2)}
            )
          }
    } 
  })
}


function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/Delivery";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.delivery.code = 'DL-'+response.data.result.number;
    } 
  })
}

function GetBoxByUser() {
  let me = this;
  let url = me.url_base + "box/get-box-by-user/"+me.user.id_user;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.box = response.data.result.code + " | " + response.data.result.user;
      me.id_box = response.data.result.id_box;
      me.payments = [];
      me.payments = [{id_box:me.id_box,box:me.box, payment_method:'008', total: (0).toFixed(2)}];
    } else {
      me.id_box = '';
      me.box = '';
      me.payments = [];
    }
  })
  .catch((error) => {
  });
}


function AddPayment() {
  this.payments.push(
    {id_box:this.id_box,box:this.box, payment_method:'008', total: (0).toFixed(2)}
  )
  this.TotalPayment();
}

function TotalPayment() {
  let total = this.total.total;
  if (this.payments.length == 0) {
    this.AddPayment();
  }else{
    let total_payment = parseFloat(total) / parseFloat(this.payments.length);
    for (let index = 0; index < this.payments.length; index++) {
      this.payments[index].id_box = this.id_box;
      this.payments[index].box = this.box;
      this.payments[index].total = parseFloat(total_payment).toFixed(2);
    }
  }
  
}

function UpdateTotalPayment() {
  for (let index = 0; index < this.payments.length; index++) {
    const element = this.payments[index];
    element.total = parseFloat(element.total).toFixed(2);
  }
}

function DeletePayment(index) {
   this.payments.splice(index, 1);
   this.TotalPayment();
}

function ModalLettersShow() {
   EventBus.$emit('ModalLettersShow',this.role);
}

function ChangeTotal() {
  this.delivery.total = parseFloat(this.delivery.total);
  this.delivery.total = this.delivery.total.toFixed(2);
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function NumberPadStart() {
  var number = String(this.delivery.number);
  number = number.padStart(8,"0");
  this.delivery.number = number;
}
function UpperCase() {
  this.delivery.serie = this.delivery.serie.toUpperCase();
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function EditDelivery() {
  let me = this;
  me.isLoading = true;
  me.delivery.id_client = me.client.id;
  me.delivery.subtotal = me.total.subtotal;
  me.delivery.delivery = me.total.delivery;
  me.delivery.discount = me.total.discount;
  me.delivery.total = me.total.total;
  me.delivery.number_to_letters = me.total.number_to_letters;
  me.delivery.delivery_detail = me.delivery_detail;
  me.delivery.payments = me.payments;
  me.delivery.id_box = me.id_box;
  let url = me.url_base + "delivery/edit";
  let data = me.delivery;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module, role:me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
          Swal.fire({ icon: 'success', text:response.data.message, timer: 3000,})
      } else{
        Swal.fire({ icon: 'error', text:response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {

 
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_warehouse = this.delivery.id_warehouse.length == 0 ? true : false;
  this.errors.code = this.delivery.code.length == 0 ? true : false;
  this.errors.date = this.delivery.date.length == 0? true : false;
  this.errors.coin = this.delivery.coin.length == 0 ? true : false;
  this.errors.delivery_detail = this.delivery_detail.length == 0 ? true : false;
  this.errors.total = parseFloat(this.total.total) <= 0 ? true : false;
  this.errors.id_box = this.id_box.length == 0 ? true : false;
  this.errors.payments = this.payments.length == 0 ? true : false;

  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_warehouse == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.coin == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.delivery_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.total == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_box == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.payments == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  Swal.fire({
    title: 'Esta seguro de modificar el delivery?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.EditDelivery();
    }
  })


}
</script>
